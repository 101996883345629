<template>
    <div class="container" id="ImportResults">
        <el-page-header @back="$router.back()" :content="subLeagueName + '-上传成绩册'"></el-page-header>
        <div class="ImportResults_content">
            <el-row>
                <el-descriptions title="下载含数据的成绩册文件" :column="1">
                    <el-descriptions-item label="组别" :contentStyle="{'display': 'inline'}">
                        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="checkAllChange">全选</el-checkbox>
                        <p style="margin-top:10px"></p>
                        <el-checkbox-group v-model="checkedGroups" @change="groupChange">
                            <el-checkbox v-for="(item,index) in groups" :label="item.GroupId" :key="index">{{item.GroupName}}</el-checkbox>
                        </el-checkbox-group>
                    </el-descriptions-item>
                </el-descriptions>
                <p style="margin-bottom:15px"><el-button type="success" size="mini" @click="exportData">下载</el-button></p>
                <p style="display:flex">
                    <el-upload
                        class="upload-demo"
                        action=""
                        :auto-upload="false"
                        :show-file-list="false"
                        accept="text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                        :on-change="importExcel">
                        <el-button type="primary" size="mini">上传成绩册文件</el-button>
                    </el-upload>
                    <el-button type="success" size="mini" style="margin-left:15px"><a href="./excel/成绩册导入模版.xlsx" style="color: inherit;text-decoration: none;">下载成绩册模板文件</a></el-button>
                </p>
            </el-row>
            <el-alert
                title="文件解析失败"
                :description="errorText"
                type="error"
                show-icon
                :closable="false"
                 v-if="errorText">
            </el-alert>
            <template v-if="list.length > 0">
                <template v-for="(item,index) in list">
                    <el-descriptions :key="'a' + index">
                        <div slot="title">
                            <p>
                                <span>组别：{{item.GroupNameList[0].GroupName}}</span>
                                <span style="font-size: 14px;color: red;" v-if="item.GroupNameList[0].GroupNowTeamCount">（该组别已有{{item.GroupNameList[0].GroupNowTeamCount}}个参赛者将被删除）</span>
                            </p>
                            <p v-if="cardsMastersInfo.MastersPayStatus > 0"><span>参赛者数量：{{item.GroupNameList[0].TeamCount}}</span></p>
                        </div>
                    </el-descriptions>
                    <el-table :data="item.GroupNameList" border size="mini"  :key="'b' + index" v-if="item.GroupNameList && item.GroupNameList.length > 0 && ['个人', '双人组合'].includes(item.GroupNameList[0].TeamType)" >
                        <el-table-column
                            prop="TeamName"
                            label="参赛者名称"
                            width="120"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="Rank"
                            label="名次"
                            width="50"
                        >
                        </el-table-column>
                        <el-table-column
                            label="代表队名称"
                            width="120"
                        >
                            <template slot-scope="scope">
                                {{scope.row.DptName}}
                                <span style="color: #409EFF" v-if="scope.row.IsdptMatch == 1" >（已存在）</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="运动员姓名"
                            :width="item.GroupNameList[0].TeamType == '双人组合' ? '140' : '100'"
                        >
                            <template slot-scope="scope">
                                {{scope.row.Name}}
                                <span style="color: #409EFF" v-if="scope.row.IsUserMatch == 1" >（已存在）</span>
                                <span style="color: #ff0000" v-else-if="scope.row.IsUserMatch == 2" >（覆盖）</span>
                                <template v-if="item.GroupNameList[0].TeamType == '双人组合'">
                                    /
                                    {{scope.row.Name2}}
                                    <span style="color: #409EFF" v-if="scope.row.IsUser2Match == 1" >（已存在）</span>
                                    <span style="color: #ff0000" v-else-if="scope.row.IsUser2Match == 2" >（覆盖）</span>
                                </template>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="手机号码"
                            width="110"
                        >
                            <template slot-scope="scope">
                                <span :style="scope.row.IsUserMatch == 1 ? 'color: #409EFF' : ''" v-if="scope.row.Phone" >{{scope.row.Phone}}</span>
                                <template v-if="item.GroupNameList[0].TeamType == '双人组合'">
                                    /
                                    <span :style="scope.row.IsUser2Match == 1 ? 'color: #409EFF' : ''" v-if="scope.row.Phone2" >{{scope.row.Phone2}}</span>
                                </template>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="身份证号码"
                            width="160"
                        >
                            <template slot-scope="scope">
                                <span :style="scope.row.IsUserMatch == 1 ? 'color: #409EFF' : ''" v-if="scope.row.IdentityNo" >{{scope.row.IdentityNo}}</span>
                                <template v-if="item.GroupNameList[0].TeamType == '双人组合'">
                                    /
                                    <span :style="scope.row.IsUser2Match == 1 ? 'color: #409EFF' : ''" v-if="scope.row.IdentityNo2" >{{scope.row.IdentityNo2}}</span>
                                </template>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="所属机构"
                            min-width="180"
                        >
                            <template slot-scope="scope">
                                <span :style="scope.row.IsBelongCompanyMatch == 1 && scope.row.BelongCompanyType != '其他' ? 'color: #409EFF' : ''">
                                    <template v-if="scope.row.BelongCompanyFullName">{{scope.row.BelongCompanyFullName}}</template>
                                    <template v-if="scope.row.BelongCompanyName">（{{scope.row.BelongCompanyName}}）</template>
                                    <template v-if="scope.row.BelongCompanyType">，{{scope.row.BelongCompanyType}}</template>
                                </span>
                                <span :style="scope.row.IsBelongCompanyMatch == 1 && scope.row.BelongComapnyMatchType != 2 ? 'color: #409EFF' : ''"  
                                    v-if="scope.row.BelongCompanyCreditcode"
                                >，{{scope.row.BelongCompanyCreditcode}}</span>
                                <span style="color: #409EFF" v-if="scope.row.IsBelongCompanyMatch == 1" >（已存在）</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="代表机构"
                            min-width="180"
                        >
                            <template slot-scope="scope">
                                <span :style="scope.row.IsStandComapnyMatch == 1 && scope.row.StandCompanyType != '其他' ? 'color: #409EFF' : ''">
                                    <template v-if="scope.row.StandCompanyFullName">{{scope.row.StandCompanyFullName}}</template>
                                    <template v-if="scope.row.StandCompanyName">（{{scope.row.StandCompanyName}}）</template>
                                    <template v-if="scope.row.StandCompanyType">，{{scope.row.StandCompanyType}}</template>
                                </span>
                                <span :style="scope.row.IsStandComapnyMatch == 1 && scope.row.StandComapnyMatchType != 2 ? 'color: #409EFF' : ''"  
                                    v-if="scope.row.StandCompanyCreditcode"
                                >，{{scope.row.StandCompanyCreditcode}}</span>
                                <span style="color: #409EFF" v-if="scope.row.IsStandComapnyMatch == 1" >（已存在）</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-table :data="item.GroupNameList" border size="small"  :key="'b' + index" v-if="item.GroupNameList && item.GroupNameList.length > 0 && !['个人', '双人组合'].includes(item.GroupNameList[0].TeamType)">
                        <el-table-column
                            prop="TeamName"
                            label="参赛者名称"
                            width="120"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="Rank"
                            label="名次"
                            width="50"
                        >
                        </el-table-column>
                        <el-table-column
                            label="代表队名称"
                            width="120"
                        >
                            <template slot-scope="scope">
                                {{scope.row.DptName}}
                                <span style="color: #409EFF" v-if="scope.row.IsdptMatch == 1" >（已存在）</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="所属机构"
                            min-width="180"
                        >
                            <template slot-scope="scope">
                                <span :style="scope.row.IsBelongCompanyMatch == 1 && scope.row.BelongCompanyType != '其他' ? 'color: #409EFF' : ''">
                                    <template v-if="scope.row.BelongCompanyFullName">{{scope.row.BelongCompanyFullName}}</template>
                                    <template v-if="scope.row.BelongCompanyName">（{{scope.row.BelongCompanyName}}）</template>
                                    <template v-if="scope.row.BelongCompanyType">，{{scope.row.BelongCompanyType}}</template>
                                </span>
                                <span :style="scope.row.IsBelongCompanyMatch == 1 && scope.row.BelongComapnyMatchType != 2 ? 'color: #409EFF' : ''"  
                                    v-if="scope.row.BelongCompanyCreditcode"
                                >，{{scope.row.BelongCompanyCreditcode}}</span>
                                <span style="color: #409EFF" v-if="scope.row.IsBelongCompanyMatch == 1" >（已存在）</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="代表机构"
                            min-width="180"
                        >
                            <template slot-scope="scope">
                                <span :style="scope.row.IsStandComapnyMatch == 1 && scope.row.StandCompanyType != '其他' ? 'color: #409EFF' : ''">
                                    <template v-if="scope.row.StandCompanyFullName">{{scope.row.StandCompanyFullName}}</template>
                                    <template v-if="scope.row.StandCompanyName">（{{scope.row.StandCompanyName}}）</template>
                                    <template v-if="scope.row.StandCompanyType">，{{scope.row.StandCompanyType}}</template>
                                </span>
                                <span :style="scope.row.IsStandComapnyMatch == 1 && scope.row.StandComapnyMatchType != 2 ? 'color: #409EFF' : ''"  
                                    v-if="scope.row.StandCompanyCreditcode"
                                >，{{scope.row.StandCompanyCreditcode}}</span>
                                <span style="color: #409EFF" v-if="scope.row.IsStandComapnyMatch == 1" >（已存在）</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </template>
                <el-divider></el-divider>
                <el-row style="margin:30px 0 20px" >
                    <el-button type="success" style="width:100px" @click="save">保存</el-button>
                </el-row>
            </template>
        </div>
    </div>
</template>
<script>
import {
    GetLeagueEventsGroup,
    ParseImportScoreOrderNameList,
    SaveImportScoreOrderNameList,
    ExportScoreOrderTplNameList
} from '@/api'
import * as XLSX from 'xlsx-js-style';
import { mapGetters } from 'vuex';
export default {
    data(){
        var id = parseInt(this.$route.params.subLeagueId);
        return {
            subLeagueId: isNaN(id) ? 0 : id,
            subLeagueName: this.$route.query.name,
            checkAll: true,
            isIndeterminate: false,
            groups:[],
            checkedGroups:[],
            rawData: [],
            list:[],
            errorText: ''
        }
    },
    created(){
        GetLeagueEventsGroup({
            MainLeagueId: this.mainLeagueId,
            SubLeagueId: this.subLeagueId
        }).then(data=>{
            if(data.Code == 0){
                this.groups = data.GroupList;
                this.checkedGroups = data.GroupList.map(item=>item.GroupId);
            }
        })
    },
    computed:{
        ...mapGetters(['cardsMastersInfo'])
    },
    methods:{
        //全选
        checkAllChange(val){
            this.checkedGroups = val ? this.groups.map(item=>item.GroupId) : [];
            this.isIndeterminate = false;
        },
        groupChange(groups){
            let checkedCount = groups.length;
            this.checkAll = checkedCount === this.groups.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.groups.length;
        },
        //保存数据
        save(){
            const loading = this.$loading({
                lock: true,
                text: '保存中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            SaveImportScoreOrderNameList({
                "MainLeagueId": this.mainLeagueId,
                "SubLeagueId": this.subLeagueId,
                "NameList": this.rawData
            }).then(data=>{
                loading.close();
                if(data.Code == 0){
                    this.list = [];
                    this.$message.success('保存成功');
                }
            }).catch(()=>{
                loading.close();
            })
        },
        //导入数据
        importExcel(file) {
            // let file = file.files[0] 
            // 使用传统的input方法需要加上这一步
            this.list = [];
            this.errorText = '';
            const types = file.name.split('.')[1];
            const fileType = ['xlsx', 'xlc', 'xlm', 'xls', 'xlt', 'xlw', 'csv'].some(item => item === types);
            if (!fileType) {
                this.errorText = '格式错误！请重新上传';
                return
            }
            const loading = this.$loading({
                lock: true,
                text: '文件解析中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            this.file2Xce(file).then(data => {
                if(data && data.length > 0){
                    let sheet = data[0].sheet,
                        arr = [];
                    if(sheet && sheet.length > 0){
                        for(var i=1;i<sheet.length;i++){
                            let obj = sheet[i];
                            if(!obj['序号'] || isNaN(parseInt(obj['序号']))) continue;
                            arr.push({
                                'SerialNo': parseInt(obj['序号']), //序号
                                'EventsName': obj['运动项目'], //运动项目
                                'GroupName': obj['报名组别'], //报名组别
                                'TeamType': obj['参赛者类型'], //参赛者类型
                                'TeamName': obj['参赛者名称'], //参赛者名称
                                'Rank': parseInt(obj['名次'] || 0),
                                'DptName': obj['代表队名称'], //代表队名称
                                'MemberNo': this.toString(obj['运动员信息']), //运动员信息
                                'Name': obj['__EMPTY'], //姓名
                                'Phone': this.toString(obj['__EMPTY_1']), //手机号码
                                'IdentityNo': this.toString(obj['__EMPTY_2']), //身份证号码
                                'BelongCompanyMemberNo': this.toString(obj['参赛者所属机构']), //所属机构会员号
                                'BelongCompanyFullName': obj['__EMPTY_3'], //所属机构全称
                                'BelongCompanyName': obj['__EMPTY_4'], //所属机构简称
                                'BelongCompanyType': obj['__EMPTY_5'], //所属机构类型
                                'BelongCompanyCreditcode': this.toString(obj['__EMPTY_6']), //所属机构社会信用代码
                                'StandCompanyMemberNo': this.toString(obj['参赛者代表机构']), //代表机构会员号
                                'StandCompanyFullName': obj['__EMPTY_7'], //代表机构全称
                                'StandCompanyName': obj['__EMPTY_8'], //代表机构简称
                                'StandCompanyType': obj['__EMPTY_9'], //代表机构类型
                                'StandCompanyCreditcode': this.toString(obj['__EMPTY_10']) //代表机构社会信用代码
                            })
                        }
                        if(arr.length > 0){
                            this.rawData = arr;
                            ParseImportScoreOrderNameList({
                                "MainLeagueId": this.mainLeagueId,
                                "SubLeagueId": this.subLeagueId,
                                "NameList": arr
                            }).then(data=>{
                                loading.close();
                                if(data.Code == 0){
                                    this.list = data.NameList;
                                }else{
                                    this.errorText = data.Message || '文件解析异常！请重新上传';
                                }
                            }).catch(()=>{
                                loading.close();
                                this.errorText = '文件解析异常！请重新上传';
                            })
                        }else{
                            loading.close();
                            this.errorText = '未读取到数据！请重新上传';
                        }
                    }else{
                        loading.close();
                        this.errorText = '未读取到数据！请重新上传';
                    }
                }else{
                    loading.close();
                    this.errorText = '未读取到数据！请重新上传';
                }
            })
        },
        file2Xce(file) {
            return new Promise(function(resolve, reject) {
                const reader = new FileReader();
                reader.onload = function(e) {
                    var data = e.target.result;
                    var wb = XLSX.read(data, {
                        type: 'binary'
                    });
                    var result = [];
                    wb.SheetNames.forEach((sheetName) => {
                        result.push({
                            sheetName: sheetName,
                            sheet: XLSX.utils.sheet_to_json(wb.Sheets[sheetName])
                        })
                    });
                    resolve(result)
                };
                reader.readAsBinaryString(file.raw)
                // reader.readAsBinaryString(file) // 传统input方法
            })
        },
        toString(data){
            return data ? data.toString() : '';
        },
        //导出
        exportData(){
            if(this.checkedGroups.length == 0){
                this.$message.warning('请选择至少一个组别');
                return ;
            }
            const loading = this.$loading({
                lock: true,
                text: '数据导出中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            ExportScoreOrderTplNameList({
                MainLeagueId: this.mainLeagueId,
                SubLeagueId: this.subLeagueId,
                GroupIds: this.checkedGroups
            }).then(data=>{
                loading.close();
                if(data.Code == 0){
                     var list = [],
                        ExcelSheet = null,
                        name = "";
                    try{
                        list = this.toArr(data.ExcelBase64Str);
                    }
                    catch{
                        this.$message.error('数据解析失败');
                        return;
                    }
                    var arr = [];
                    for(var i=0;i<list.length;i++){
                        let rowData = list[i],
                            row = [];
                        for(var j=0;j<rowData.length;j++){
                            let colText = rowData[j];
                            if(i == 0){
                                colText = colText ? colText : '__EMPTY';
                                row.push({ 
                                    v: j < 7 ? list[1][j] : colText, 
                                    t: 's', 
                                    s: { 
                                        font: {
                                            sz: 11 
                                        },
                                        alignment: { 
                                            vertical: 'center',
                                            horizontal: 'center'
                                        }
                                    } 
                                })
                            }else if(i == 1){
                                row.push({ 
                                    v: colText, 
                                    t: 's', 
                                    s: { 
                                        font: {
                                            sz: 11 
                                        },
                                        alignment: { 
                                            horizontal: 'left'
                                        }
                                    } 
                                })
                            }else{
                                if([0,5].includes(j)){
                                    row.push({ 
                                        v: parseInt(colText), 
                                        t: 'n',
                                        s: { 
                                            font: {
                                                sz: 11 
                                            },
                                            alignment: { 
                                                horizontal: 'right'
                                            }
                                        } 
                                    })
                                }else{
                                    row.push({ 
                                        v: colText, 
                                        t: 's',
                                        s: { 
                                            font: {
                                                sz: 11 
                                            },
                                            alignment: { 
                                                horizontal: [3,10,14,19].includes(j) ? 'left' : 'right'
                                            }
                                        }
                                    })
                                }
                            }
                        }
                        arr.push(row);
                    }
                    ExcelSheet = XLSX.utils.aoa_to_sheet(arr);
                    ExcelSheet['!cols'] = [
                        {wpx: 50},
                        {wpx: 100},
                        {wpx: 100},
                        {wpx: 80},
                        {wpx: 100},
                        {wpx: 50},
                        {wpx: 100},
                        {wpx: 100},
                        {wpx: 100},
                        {wpx: 100},
                        {wpx: 140},
                        {wpx: 140},
                        {wpx: 140},
                        {wpx: 140},
                        {wpx: 80},
                        {wpx: 140},
                        {wpx: 140},
                        {wpx: 140},
                        {wpx: 140},
                        {wpx: 80},
                        {wpx: 140},
                    ];
                    ExcelSheet['!merges'] = [{
                        s:{c: 0,r: 1},
                        e:{c: 0,r: 0}
                    },{
                        s:{c: 1,r: 0},
                        e:{c: 1,r: 1}
                    },{
                        s:{c: 2,r: 0},
                        e:{c: 2,r: 1}
                    },{
                        s:{c: 3,r: 0},
                        e:{c: 3,r: 1}
                    },{
                        s:{c: 4,r: 0},
                        e:{c: 4,r: 1}
                    },{
                        s:{c: 5,r: 0},
                        e:{c: 5,r: 1}
                    },{
                        s:{c: 6,r: 0},
                        e:{c: 6,r: 1}
                    },{
                        s:{c: 7,r: 0},
                        e:{c: 10,r: 0}
                    },{
                        s:{c: 11,r: 0},
                        e:{c: 15,r: 0}
                    },{
                        s:{c: 16,r: 0},
                        e:{c: 20,r: 0}
                    }]
                    if(this.checkedGroups.length == 1){
                        let groupName = this.groups.filter(item=>item.GroupId == this.checkedGroups[0]);
                        name = this.subLeagueName + groupName[0].GroupName + '成绩册.xlsx';
                    }else{
                        name = this.subLeagueName + this.checkedGroups.length + '个组别成绩册.xlsx';
                    }
                    const wb = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(wb, ExcelSheet, "参赛者表");
                    XLSX.writeFile(wb, name);
                }
            }).catch(()=>{
                loading.close();
            })
        },
        toArr(dataBase64){
            var data = new Buffer(dataBase64, 'base64').toString('utf8');
            var arr = data.split(/\n/),
                arr2 = [];
            for(var i = 0;i<arr.length;i++){
                if(arr[i]){
                    arr2.push(arr[i].split(',').map(n=>JSON.parse(n)));
                }
            }
            return arr2;
        },
         //导出excel相关函数
        sheet2blob(sheets = [], sheetNames = []) {
            var workbook = {
                SheetNames: [],
                Sheets: {}
            };
            for(var i=0;i<sheets.length;i++){
                let sheetName = sheetNames[i] || ('sheet' + (i + 1));
                workbook.Sheets[sheetName] = sheets[i];
                workbook.SheetNames.push(sheetName);
            }
            // 生成excel的配置项
            var wopts = {
                bookType: 'xlsx', // 要生成的文件类型
                bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
                type: 'binary'
            };
            var wbout = XLSX.write(workbook, wopts);
            var blob = new Blob([s2ab(wbout)], {type:"application/octet-stream"});
            // 字符串转ArrayBuffer
            function s2ab(s) {
                var buf = new ArrayBuffer(s.length);
                var view = new Uint8Array(buf);
                for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
            }
            return blob;
        },
        openDownloadXLSXDialog(url, saveName){
            if(typeof url == 'object' && url instanceof Blob){
                url = URL.createObjectURL(url); // 创建blob地址
            }
            var aLink = document.createElement('a');
            aLink.href = url;
            aLink.download = saveName || ''; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
            var event;
            if(window.MouseEvent) event = new MouseEvent('click');
            else{
                event = document.createEvent('MouseEvents');
                event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
            }
            aLink.dispatchEvent(event);
        }
    }
}
</script>
<style scoped>
#ImportResults :v-deep(.el-descriptions__header){
    margin: 15px 0;
}
#ImportResults :v-deep(.el-table){
    margin-bottom: 20px;
}
</style>